.generalDataWrapper {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	margin-bottom: 16px;
	margin-top: -55px;
	.lastUpdate {
		color: #7A7A7A;
		font-size: 14px;
		font-family: 'Roboto';
		line-height: 17.5px;
		position: absolute;
		top: 0px;
		right: 40px;
		.lastUpdateDate{
		color:#111111;
		}
	}
}