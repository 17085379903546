.contentFullDetail {
	overflow: auto;
	overflow-x: hidden;
	position: absolute;
	bottom: 0px;
	top: 180px;
	padding-top: 40px;
	width: 100%;
	&.dateLastValidation {
		top: 210px;
	}
}